import {FC} from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';

import Arrow from '@/assets/icons_refactor/Common/long-arrow.svg';
import ErrorMessageText from '@/src/components/AuthModal/Form/ErrorMessageText';
import useConfig from '@/src/hooks/useConfig';
import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';

import * as Styled from './styled';
import Consts from '../../../AuthModalConsts';

const {TERMS, CREATE_ACCOUNT_TITLE, PRIVACY, LOGIN, HAVE_ACC} = Consts;

const {login} = ModalMode;

interface Props {
  handleOpenModal: (type: ModalMode) => void;
  agreeTermsValue: boolean;
  setAgreeTermsValue: (value: boolean) => void;
}

const Footer: FC<Props> = ({handleOpenModal, agreeTermsValue, setAgreeTermsValue}) => {
  const {blendDomainURL = '', companyName = ''} = useConfig() || {};

  const termsOfUseUrl = `https://${blendDomainURL}/terms-and-conditions`;
  const privacyPolicyUrl = `https://${blendDomainURL}/privacy-policy`;

  const {errorMessages, isFetchingAuth} = useUserData();
  const {agree_terms} = errorMessages;

  return (
    <Styled.Wrapper>
      <Styled.ErrorMessage>
        <ErrorMessageText data-qa-auto="erragree" message={agree_terms && agree_terms[0]} />
      </Styled.ErrorMessage>
      <Styled.AgreeTerms>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTermsValue}
                name="agreeTerms"
                color="primary"
                onChange={({target: {checked}}) => {
                  setAgreeTermsValue(checked);
                }}
                inputProps={{'aria-label': 'primary checkbox'}}
                disabled={isFetchingAuth}
              />
            }
            label=""
          />
        </FormControl>
        {CREATE_ACCOUNT_TITLE} {companyName}&#39;s
      </Styled.AgreeTerms>
      <div>
        <Link href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">
          {TERMS}
        </Link>
        &nbsp;&&nbsp;
        <Link href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
          {PRIVACY}
        </Link>
      </div>

      <Styled.SwitchBox>
        {HAVE_ACC}
        <Styled.Button data-qa-auto="switch-to-login-mode" onClick={() => handleOpenModal(login)} disableRipple>
          {LOGIN}
          <Styled.Icon icon={Arrow} boxW={20} boxH={20} />
        </Styled.Button>
      </Styled.SwitchBox>
    </Styled.Wrapper>
  );
};

export default Footer;

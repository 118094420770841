import {FC, useState} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';

import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import ConstTheme from '@/theme';

import Consts from '../AuthModalConsts';
import AuthSocial from '../AuthSocial';
import Title from '../Title';
import Footer from './components/Footer';
import * as Styled from './styled';

const Form = dynamic(() => import('./components/Form/Form'), {ssr: false});

const {CREATE_ACC} = Consts;
interface Props {
  isOpen: boolean;
  handleCloseModal: VoidFunction;
  handleOpenModal: (type: ModalMode) => void;
  needSocialButtons?: boolean;
  registrationPage: any;
  redirectTo?: string;
}

const SignUpModal: FC<Props> = ({
  isOpen,
  handleCloseModal,
  handleOpenModal,
  needSocialButtons = false,
  registrationPage,
  redirectTo,
}) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useUserData();

  const [email, setCurrentEmail] = useState<string>('');
  const [agreeTerms, setAgreeTerms] = useState<boolean>(true);

  return (
    <Styled.DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={isOpen}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <Title id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {CREATE_ACC}
      </Title>
      <Styled.ModalContent>
        {needSocialButtons && <AuthSocial />}
        <Form
          email={email}
          emailUpdatedHandler={setCurrentEmail}
          registrationPage={registrationPage}
          agreeTermsValue={agreeTerms}
          redirectTo={redirectTo}
        />

        <Footer handleOpenModal={handleOpenModal} agreeTermsValue={agreeTerms} setAgreeTermsValue={setAgreeTerms} />
      </Styled.ModalContent>
    </Styled.DialogItem>
  );
};

export default SignUpModal;

import {FC, useState} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';

import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import ConstTheme from '@/theme';

import Consts from '../AuthModalConsts';
import AuthSocial from '../AuthSocial';
import Title from '../Title';
import Footer from './components/Footer';
import * as Styled from './styled';

const {LOGIN_TITLE} = Consts;

const Form = dynamic(() => import('./components/Form/Form'), {ssr: false});

interface Props {
  isOpen: boolean;
  handleCloseModal: VoidFunction;
  handleOpenModal: (type: ModalMode) => void;
  needSocialButtons?: boolean;
  redirectTo?: string;
}

const LoginModal: FC<Props> = ({isOpen, handleCloseModal, handleOpenModal, needSocialButtons = false, redirectTo}) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useUserData();

  const [email, setCurrentEmail] = useState<string>('');

  return (
    <Styled.DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={isOpen}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <Title id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {LOGIN_TITLE}
      </Title>
      <Styled.ModalContent>
        {needSocialButtons && <AuthSocial />}
        <Form emailUpdatedHandler={setCurrentEmail} emailParam={email} redirectTo={redirectTo} />
        <Footer handleOpenModal={handleOpenModal} email={email} />
      </Styled.ModalContent>
    </Styled.DialogItem>
  );
};

export default LoginModal;

'use client';

import Box from '@mui/material/Box';
import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';

export const Wrapper = styled.div`
  font-size: 0.9rem;
  color: ${({theme}) => theme.colors.green084};
  text-align: center;
  font-weight: 300;

  & a,
  & button {
    padding: 0;
    color: ${({theme}) => theme.colors.blue093};
    vertical-align: baseline;
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.colors.blue093};
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

export const Button = styled(BaseButton)`
  margin-left: 5px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
`;

export const AgreeTerms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & label {
    margin: 0;

    & > span {
      padding: 5px;
    }
  }
`;

export const SwitchBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.green084};
`;

export const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue093};
  margin-left: 2px;
`;

import {FC, ReactNode} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import * as Styled from './styled';

interface Props {
  children?: ReactNode;
  onClose: () => void;
  id: string;
  disableClose: boolean;
}

const Title: FC<Props> = ({children, onClose, disableClose = false, ...other}) => (
  <Styled.Wrapper {...other}>
    <Box>
      {!!onClose && (
        <IconButton aria-label="close" onClick={onClose} disabled={disableClose} size="large">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
    <Styled.Content align="center">{children}</Styled.Content>
  </Styled.Wrapper>
);

export default Title;

'use client';

import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import {font_header_large, headerSmallFontSize} from '@/theme/fonts';

export const Wrapper = styled(MuiDialogTitle)`
  margin: 0;
  padding: 8px 30px;

  & div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & button {
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

export const Content = styled(Typography)`
  ${font_header_large};

  @media (max-width: 480px) {
    ${headerSmallFontSize};
  }
`;

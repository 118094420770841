'use client';

import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';

export const ModalContent = styled(MuiDialogContent)`
  padding: 8px 30px;

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

export const DialogItem = styled(Dialog)`
  &.MuiDialog-paper {
    overflow: visible;
    max-width: 560px;
    border-radius: 0.75rem;

    @media ${({theme}) => theme.breakpoints.maxSm} {
      margin: 10px;
      width: 100%;
    }
  }
`;

import Link from '@mui/material/Link';

import Arrow from '@/assets/icons_refactor/Common/long-arrow.svg';
import {ModalMode} from '@/src/interfaces/types/auth.types';

import * as Styled from './styled';
import Consts from '../../../AuthModalConsts';

const {FORGOT_PASSWORD, SIGN_UP, WITHOUT_ACCOUNT} = Consts;

const {signup} = ModalMode;
interface Props {
  handleOpenModal: (type: ModalMode) => void;
  email?: string;
}

const Footer = ({handleOpenModal, email}: Props) => (
  <Styled.Wrapper>
    <Link href={`/auth/resetPassword${email ? '?email=' + email : ''}`} target="__blank" rel="noopener noreferrer">
      {FORGOT_PASSWORD}
    </Link>

    <Styled.SwitchBox>
      {WITHOUT_ACCOUNT}
      <Styled.Button data-qa-auto="switch-to-signup-mode" onClick={() => handleOpenModal(signup)} disableRipple>
        {SIGN_UP}
        <Styled.Icon icon={Arrow} />
      </Styled.Button>
    </Styled.SwitchBox>
  </Styled.Wrapper>
);

export default Footer;
